import type { ProgrammeId } from '@/common/types';
import { type IAGUser } from './user';
import { AERC, ATRP, BAEC, IBP } from './constants';

interface Props {
  user?: IAGUser;
  programmeId?: ProgrammeId;
  balance?: number | null;
}

export enum Iso {
  GB = 'GB',
  IE = 'IE',
  ES = 'ES',
}

export enum ProgrammeCode {
  BAEC = 'british_airways',
  IBP = 'iberia',
  AERC = 'aer_lingus',
  ATRP = 'vueling',
}

export interface DataLayer {
  isLoggedIn: boolean;
  Iso: Iso;
  product: string;
  programmeCode: ProgrammeCode;
  memberID: null | string;
  balance: null | number;
  domain_environment: string;
  channel: null;
  partner_name: null;
  booking_currency: null;
  avios_from_booster: null;
  avios_from_subscription: null;
}

export const getIso = (programmeId?: ProgrammeId) => {
  switch (programmeId) {
    case BAEC:
      return Iso.GB;
    case AERC:
      return Iso.IE;
    case IBP:
    case ATRP:
      return Iso.ES;
    default:
      return Iso.GB;
  }
};

export const getConvertedProgrammeId = (
  programmeName: ProgrammeCode,
): ProgrammeId => {
  switch (programmeName) {
    case ProgrammeCode.BAEC:
      return 'BAEC';
    case ProgrammeCode.IBP:
      return 'IBP';
    case ProgrammeCode.ATRP:
      return 'ATRP';
    case ProgrammeCode.AERC:
      return 'AERC';
    default:
      throw new Error(
        `Programme name ${programmeName} not found in the mapping`,
      );
  }
};

export const getConvertedProgrammeCode = (programmeId?: ProgrammeId) => {
  switch (programmeId) {
    case BAEC:
      return 'british_airways';
    case AERC:
      return 'aer_lingus';
    case IBP:
      return 'iberia';
    case ATRP:
      return 'vueling';
    default:
      return 'british_airways';
  }
};

export const buildDataLayerInfo = ({
  user,
  programmeId,
  balance,
}: Props): DataLayer => {
  const data = {
    isLoggedIn: !!user,
    Iso: getIso(programmeId),
    product: 'avios',
    programmeCode: getConvertedProgrammeCode(programmeId) as ProgrammeCode,
    memberID: user ? user.memberId : null,
    balance: balance ?? null,
    domain_environment:
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd' ? 'prod' : 'non-prod',
    channel: null,
    partner_name: null,
    booking_currency: null,
    avios_from_booster: null,
    avios_from_subscription: null,
  };

  return data;
};
