import type { AppProps } from 'next/app';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { appWithTranslation } from 'next-i18next';
import MainLayout from '@/components/MainLayout';
import PageTitle from '@/components/PageTitle';
import DatadogRum from '@/components/DatadogRum';
import Theme from '@/theme';
import '../../styles/globals.css';
import type { ProgrammeId } from '@/common/types';
import { useIdleDetection } from '@/utils/useIdleDetection';

const LinkingApp = ({ Component, pageProps }: AppProps) => {
  const {
    programmeId,
  }: { programmeId: ProgrammeId; singleAviosBalance: number } = pageProps;
  const isIdle = useIdleDetection();

  return (
    <>
      <DatadogRum />
      <UserProvider loginUrl="/api/auth/login" profileUrl="/api/auth/me">
        <PageTitle />
        <Theme programmeId={programmeId}>
          <MainLayout {...pageProps}>
            <Component {...pageProps} isIdle={isIdle} />
          </MainLayout>
        </Theme>
      </UserProvider>
    </>
  );
};

export default appWithTranslation(LinkingApp);
