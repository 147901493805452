import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { Link, ProgrammeId, Transaction } from '@/common/types';
import { useLinks } from '@/utils/useLinks';
import type { PopupPartner } from '@/components/Popup';

export type GlobalContextProps = {
  programmeId?: ProgrammeId;
  singleAviosBalance?: number;
  setSingleAviosBalance?: (balance: number) => void;
  transactions?: Transaction[] | null;
  setTransactions?: (transactions: Transaction[]) => void;
  links?: Link[];
  getIagLinks?: () => Link[];
  getExternalLinks?: () => Link[];
  setLinks?: (links: Link[]) => void;
  removeLink?: (linkId: string) => void;
  updateLink?: (code: string, options: Partial<Link>) => void;
  onLinkCallback?: (code: string) => void;
  setLinkRegisteredAction?: (callback: (code: string) => void) => void;
  onUnlinkCallback?: (linkId: string, partner: PopupPartner) => void;
  setUnlinkRegisteredAction?: (
    callback: (linkId: string, partner: PopupPartner) => void,
  ) => void;
};

const GlobalContext = createContext<GlobalContextProps>({});

export interface GlobalProviderProps {
  programmeId: ProgrammeId;
  singleAviosBalance: number;
  links: Link[];
  transactions: Transaction[] | null;
}

export function Index({
  programmeId,
  singleAviosBalance: inputSingleAviosBalance,
  links: initialLinks,
  transactions: initialTransactions,
  children,
}: Readonly<PropsWithChildren<GlobalProviderProps>>) {
  const [singleAviosBalance, setSingleAviosBalance] = useState<number>(
    inputSingleAviosBalance,
  );
  const [transactions, setTransactions] = useState<Transaction[]>(
    initialTransactions ?? [],
  );
  const {
    links,
    getIagLinks,
    getExternalLinks,
    removeLink,
    updateLink,
    setLinks,
    onLinkCallback,
    setLinkRegisteredAction,
    onUnlinkCallback,
    setUnlinkRegisteredAction,
  } = useLinks(initialLinks);

  useMemo(() => {
    setSingleAviosBalance(inputSingleAviosBalance);
  }, [inputSingleAviosBalance, initialTransactions]);

  useMemo(() => {
    setTransactions(initialTransactions ?? []);
  }, [initialTransactions]);

  const value = useMemo<GlobalContextProps>(
    () => ({
      programmeId,
      singleAviosBalance,
      setSingleAviosBalance,
      transactions,
      setTransactions,
      links,
      getIagLinks,
      getExternalLinks,
      setLinks,
      removeLink,
      updateLink,
      onLinkCallback,
      setLinkRegisteredAction,
      onUnlinkCallback,
      setUnlinkRegisteredAction,
    }),
    [
      programmeId,
      singleAviosBalance,
      links,
      transactions,
      onLinkCallback,
      onUnlinkCallback,
    ],
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = (): GlobalContextProps => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('Failed to access the global context.');
  }

  return context;
};

export default Index;
