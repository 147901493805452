import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { AERC } from '@/utils/constants';
import type { Font, Palette } from '../types';
import Frutiger from '../fonts/frutiger';
import Diodrum from '../fonts/diodrum';

export const font: Font = {
  primary: 'Frutiger Neue LT Pro',
  secondary: 'Diodrum',
};

export const fontFamilies = css`
  ${Frutiger};
  ${Diodrum};

  body {
    font-family: ${font.primary}, ${font.secondary}, sans-serif;
  }
`;

export const primaryPalette: Palette = {
  teal: {
    p50: '#F2F7F8',
    p100: '#E5EFF0',
    p200: '#ACCCD1',
    p300: '#73A9B1',
    p400: '#00788C',
    p500: '#006272',
    p600: '#005361',
    p700: '#0B4C58',
    p800: '#00363F',
    p900: '#00272E',
  },
  shamrock: {
    p50: '#F9FBF2',
    p100: '#E2EFC2',
    p200: '#CAE391',
    p300: '#B3D661',
    p400: '#9BCA31',
    p500: '#84bd00',
    p600: '#70A100',
    p700: '#5C8400',
    p800: '#496800',
    p900: '#354C00',
  },
  grey: {
    p0: '#FFFFFF',
    p50: '#F9F9F9',
    p100: '#F1F1F1',
    p200: '#D0D0CE',
    p300: '#BBBCBC',
    p400: '#A6A7A8',
    p500: '#888B8D',
    p600: '#63666A',
    p700: '#515254',
    p800: '#3C3B3B',
    p900: '#2C2A29',
    p1: '#000000',
  },
};

export const highlight: Palette = {
  green: '#008374',
};

export const baseGreen = '#F5F8F7';
export const typographyDark = '#313838';

const theme: DefaultTheme = {
  name: AERC,
  button: {
    background: primaryPalette.teal.p500,
    borderColor: 'transparent',
    borderRadius: '100px',
    color: '#fff',
    disabled: primaryPalette.teal.p700,
    fontFamily: font.secondary,
    highlight: primaryPalette.teal.p400,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
  },
  card: {
    name: {
      fontFamily: font.secondary,
      color: primaryPalette.teal.p900,
      letterSpacing: '0.02rem',
      textTransform: 'none',
    },
    userId: {
      fontFamily: font.secondary,
      color: primaryPalette.grey.p500,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      letterSpacing: '0.06rem',
    },
    balance: {
      wrapperBackground: primaryPalette.teal.p50,
      color: primaryPalette.teal.p500,
      fontFamily: font.secondary,
      fontSize: '1.6rem',
    },
    unlink: {
      color: primaryPalette.teal.p400,
      fontFamily: font.secondary,
      fontSize: '1.2rem',
      fontWeight: '400',
      lineHeight: '1.8rem',
      unlinkStroke: primaryPalette.teal.p500,
      unlinkHoverStroke: '#C55050',
    },
    iconStroke: primaryPalette.teal.p500,
  },
  login: {
    containerBackground: primaryPalette.teal.p50,
    margin: '7rem 0 7rem 2.4rem',
    title: {
      color: primaryPalette.teal.p500,
      fontFamily: font.secondary,
      fontSize: '5rem',
      fontWeight: '300',
      lineHeight: '6rem',
      textTransform: 'none',
    },
    subtitle: {
      color: primaryPalette.teal.p500,
      fontFamily: font.secondary,
      fontSize: '2.4rem',
      fontWeight: '300',
      lineHeight: '3.2rem',
      textTransform: 'none',
    },
    description: {
      fontFamily: font.primary,
      fontSize: '1.6rem',
      color: primaryPalette.grey.p600,
      lineHeight: '2.4rem',
      fontWeight: '400',
    },
    button: {
      fontWeight: '400',
      alignment: 'center',
    },
    row: {
      gap: '2rem',
      tabletGap: '2rem',
      marginRight: '4rem',
    },
  },
  loginPartners: {
    title: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p600,
      fontWeight: 'normal',
      lineHeight: '2.1rem',
      fontSize: '1.4rem',
    },
  },
  logout: {
    fontFamily: font.primary,
    color: '#71737f',
    fontSize: '1.6rem',
  },
  sidebar: {
    menuBackground: primaryPalette.teal.p50,
    listItem: {
      color: typographyDark,
      colorHighlight: primaryPalette.teal.p500,
      fontFamily: font.secondary,
      fontSize: '1.6rem',
      textTransform: 'none',
      fontWeight: '400',
      lineHeight: '2.2rem',
    },
    link: {
      colorHover: primaryPalette.teal.p500,
    },
  },
  singleAviosBalance: {
    label: {
      fontFamily: font.secondary,
      fontSize: '1.2rem',
      fontWeight: '600',
      lineHeight: '2rem',
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      mobile: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    balance: {
      fontFamily: font.secondary,
      fontSize: '3.8rem',
      fontWeight: '300',
      lineHeight: '4.6rem',
      mobile: {
        fontSize: '3rem',
        lineHeight: '4.5rem',
      },
    },
    contentGap: '0',
  },
  spinner: {
    labelColor: primaryPalette.grey.p500,
    borderColor: primaryPalette.grey.p50,
    borderTopColor: primaryPalette.teal.p500,
  },
  subHeader: {
    borderColor: primaryPalette.grey.p200,
    titleColor: primaryPalette.teal.p500,
    fontFamily: font.secondary,
    fontWeight: '500',
    fontSize: '3rem',
    margin: '1.5rem 0 0',
    titlePadding: '0 0 2rem',
    textTransform: 'none',
    lineHeight: '3.8rem',
    mobile: {
      fontSize: '2rem',
      lineHeight: '2.8rem',
    },
  },
  tooltip: {
    fontFamily: font.primary,
    color: primaryPalette.grey.p600,
    fontSize: '1.4rem',
    title: {
      fontFamily: font.secondary,
      fontWeight: '600',
      color: primaryPalette.grey.p900,
      fontSize: '1.6rem',
      letterSpacing: '0.04rem',
      textTransform: 'none',
    },
  },
  walletView: {
    containerBackground: primaryPalette.teal.p50,
    header: {
      fontFamily: font.secondary,
      color: primaryPalette.grey.p800,
      fontSize: '3rem',
      fontWeight: '400',
      lineHeight: '3.8rem',
      textTransform: 'none',
    },
    subtitle: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p600,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.2rem',
      mobile: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
    latestTransactions: {
      headline: {
        fontFamily: font.secondary,
        color: primaryPalette.grey.p600,
        fontWeight: '600',
        fontSize: '1.2rem',
        letterSpacing: '0.1rem',
        mobile: {
          fontSize: '1rem',
        },
      },
      extraAction: {
        fontFamily: font.primary,
        color: primaryPalette.teal.p500,
        fontSize: '1.2rem',
        mobile: {
          fontSize: '1rem',
        },
      },
      description: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p600,
        fontWeight: '400',
        fontSize: '1.4rem',
      },
      amount: {
        fontFamily: font.secondary,
        color: primaryPalette.grey.p600,
        fontSize: '1.8rem',
        fontWeight: 'normal',
        lineHeight: '2.4rem',
      },
      emptyTransactions: {
        color: primaryPalette.grey.p500,
        fontFamily: font.secondary,
        fontSize: '1.4rem',
      },
      errorTransactions: {
        color: '#E8111C',
        fontFamily: font.secondary,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
  },
  popup: {
    confirmation: {
      title: {
        fontFamily: font.secondary,
        textTransform: 'none',
      },
      description: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p600,
      },
      cancelButton: {
        borderColor: primaryPalette.teal.p500,
        color: primaryPalette.teal.p500,
        backgroundColor: '#fff',
        borderWidth: '2px',
      },
      successButton: {
        textTransform: 'none',
      },
    },
    link: {
      fontFamily: font.primary,
    },
    unlinkIconStrike: '#C55050',
  },
  latestTransactions: {
    containerBackground: baseGreen,
    header: {
      fontFamily: font.secondary,
      color: primaryPalette.grey.p800,
      fontSize: '3rem',
      fontWeight: '400',
    },
    filterTitle: {
      fontFamily: font.secondary,
      color: primaryPalette.grey.p800,
      fontWeight: '600',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
    subtitle: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p600,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.2rem',
    },
    table: {
      header: {
        background: primaryPalette.teal.p100,
        fontFamily: font.primary,
        fontWeight: '500',
        color: primaryPalette.grey.p800,
        fontSize: '1.4rem',
      },
      item: {
        color: primaryPalette.grey.p800,
        background: primaryPalette.grey.p50,
        fontFamily: font.primary,
        amountColor: primaryPalette.grey.p800,
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
      },
      error: {
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        color: '#E8111C',
      },
      empty: {
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        color: primaryPalette.grey.p600,
      },
    },
    pagination: {
      activeColor: primaryPalette.teal.p500,
      disabledColor: primaryPalette.teal.p200,
    },
  },
  dropdown: {
    fontFamily: font.secondary,
    color: primaryPalette.teal.p500,
    borderColor: primaryPalette.teal.p900,
    labelColor: primaryPalette.grey.p600,
    hintColor: primaryPalette.grey.p900,
    hoverBackground: primaryPalette.grey.p100,
    titleFontWeight: '500',
    menuListHeight: '18rem',
    selected: {
      color: primaryPalette.teal.p900,
      iconColor: primaryPalette.teal.p500,
    },
  },
  dateSelector: {
    borderColor: primaryPalette.teal.p900,
    color: primaryPalette.grey.p900,
    fontFamily: font.primary,
    label: {
      color: primaryPalette.grey.p600,
      fontFamily: font.secondary,
      fontWeight: '500',
    },
    calendar: {
      selectedColor: primaryPalette.teal.p500,
      dayName: {
        color: primaryPalette.teal.p500,
      },
      today: {
        color: primaryPalette.grey.p100,
      },
    },
  },
  footer: {
    background: primaryPalette.teal.p500,
    color: primaryPalette.grey.p0,
    fontFamily: font.primary,
  },
  header: {
    background: primaryPalette.teal.p500,
    color: primaryPalette.grey.p0,
    fontFamily: font.primary,
    isCentered: false,
    borderBottom: '1px solid #f1f1f2',
    boxShadow: 'none',
    userIcon: {
      stroke: primaryPalette.teal.p300,
      fill: primaryPalette.teal.p700,
    },
    userMenu: {
      title: {
        fontFamily: font.primary,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        color: primaryPalette.grey.p600,
        letterSpacing: '0',
      },
      text: {
        fontFamily: font.secondary,
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '2.8rem',
        color: primaryPalette.teal.p500,
      },
      link: {
        fontFamily: font.secondary,
        fontWeight: '400',
        color: primaryPalette.grey.p800,
        letterSpacing: '0.02rem',
      },
      icons: {
        userFill: '#b8b8b8',
        circleFill: '#414141',
        circleStroke: '#b8b8b8',
      },
      aviosBalance: {
        fontFamily: font.secondary,
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '2.8rem',
        color: primaryPalette.teal.p500,
      },
      myAccount: {
        background: '#f8f8f8',
      },
      logout: {
        background: '#f8f8f8',
      },
      errorColor: '#C55050',
      popupRightPosition: '0.8rem',
      expiryDateColor: primaryPalette.grey.p600,
    },
    rightBlock: {
      mobileDisplay: 'none',
    },
  },
  languageSelector: {
    container: {
      fontFamily: font.primary,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      fontWeight: '500',
      letterSpacing: '0.02rem',
      color: '#fff',
      padding: '0.5rem',
      tabletDisplay: 'inherit',
    },
    title: {
      fontFamily: font.primary,
      fontSize: '1.6rem',
      color: primaryPalette.grey.p900,
      lineHeight: '2.4rem',
    },
    button: {
      fontSize: '1.8rem',
      fontWeight: '400',
    },
    chevronStroke: '#fff',
  },
  help: {
    arrowColor: primaryPalette.teal.p500,
    background: primaryPalette.grey.p50,
    fontFamilyTitle: font.primary,
    fontFamilyText: font.primary,
    titleColor: primaryPalette.grey.p900,
    textColor: primaryPalette.grey.p700,
    textTransformTitle: 'none',
    fontSizeTitle: '3.4rem',
    fontWeightTitle: '300',
    lineHeightTitle: '5.1rem',
    fontSizeText: '1.2rem',
    fontWeightText: '300',
    helpMenu: {
      border: `2px solid ${primaryPalette.teal.p500}`,
      hoverBorderColor: `2px solid ${primaryPalette.teal.p200}`,
    },
  },
  contactUs: {
    title: {
      color: primaryPalette.grey.p800,
      fontFamily: font.secondary,
      fontSize: '3rem',
      fontWeight: '400',
      lineHeight: '4.5rem',
    },
    description: {
      color: primaryPalette.grey.p600,
      fontFamily: font.primary,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    formTitle: {
      color: primaryPalette.grey.p800,
      fontFamily: font.secondary,
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '3rem',
    },
    input: {
      borderColor: primaryPalette.grey.p500,
      textColor: primaryPalette.grey.p500,
      textFontFamily: font.primary,
    },
    textAreaDescription: {
      color: primaryPalette.grey.p600,
      fontFamily: font.primary,
      fontSize: '1.4rem',
    },
    textAreaCount: {
      color: primaryPalette.grey.p600,
      fontFamily: font.primary,
      fontSize: '1.2rem',
      fontWeight: '300',
    },
    submissionResult: {
      title: {
        color: primaryPalette.grey.p900,
        errorColor: '#E8111C',
        fontFamily: font.primary,
        fontSize: '2.8rem',
        fontSizeTablet: '2.6rem',
        fontSizeMobile: '2.4rem',
        fontWeight: '500',
        lineHeight: '3.6rem',
        lineHeightTablet: '3.4rem',
        lineHeightMobile: '3rem',
        letterSpacing: '0',
        textTransform: 'none',
      },
      description: {
        color: primaryPalette.grey.p700,
        fontFamily: font.primary,
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.8rem',
      },
    },
  },
};

export default theme;
