import type { GetServerSideProps } from 'next';
import Login from '@/components/Login';
import { getSortedPartnerships } from '@/services/getSortedPartnerships';
import { getCurrentProgrammeId } from '@/utils/programme';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { sharedTranslationNamespaces } from '@/utils/constants';
import { getCurrentUser } from '@/utils/user';
import { buildDataLayerInfo } from '@/utils/dataLayer';

export const getServerSideProps: GetServerSideProps = async context => {
  const programmeId = getCurrentProgrammeId(context.req);
  const partners = await getSortedPartnerships(programmeId);

  const user = await getCurrentUser(context.req, context.res, {
    isLoggedInAction: false,
  });

  return {
    props: {
      programmeId,
      partners,
      dataLayerInfo: buildDataLayerInfo({ user, programmeId }),
      ...(await serverSideTranslations(context.locale ?? 'en', [
        ...sharedTranslationNamespaces,
        `${programmeId}/login`,
      ])),
    },
  };
};

export default Login;
